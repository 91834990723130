// extracted by mini-css-extract-plugin
export var blackButton = "home-internet-module--blackButton--5d404";
export var carouselItem = "home-internet-module--carouselItem--c4016";
export var chatMessageFixed = "home-internet-module--chatMessageFixed--a9644";
export var checkAvailabilityForm = "home-internet-module--checkAvailabilityForm--723c8";
export var checkAvailabilityPopUpForm = "home-internet-module--checkAvailabilityPopUpForm--cb5b7";
export var choosePlanButton = "home-internet-module--choosePlanButton--a2eb2";
export var faqAnswer = "home-internet-module--faqAnswer--9e6de";
export var faqItem = "home-internet-module--faqItem--896d3";
export var faqListContainer = "home-internet-module--faqListContainer--ff3f7";
export var faqOpen = "home-internet-module--faqOpen--8f139";
export var faqQuestion = "home-internet-module--faqQuestion--23a90";
export var faqSection = "home-internet-module--faqSection--f19b9";
export var formPart1 = "home-internet-module--formPart1--1faf0";
export var formPart1LeftSide = "home-internet-module--formPart1LeftSide--33f5c";
export var formPart2 = "home-internet-module--formPart2--69eed";
export var heroSection = "home-internet-module--heroSection--954da";
export var homeInternetContainer = "home-internet-module--homeInternetContainer--16843";
export var howToSection = "home-internet-module--howToSection--231d4";
export var howToStep = "home-internet-module--howToStep--eb92d";
export var howToStepsContainer = "home-internet-module--howToStepsContainer--1036c";
export var infoSection = "home-internet-module--infoSection--14eb3";
export var isSpecialPlan = "home-internet-module--isSpecialPlan--276ec";
export var planBanner = "home-internet-module--planBanner--7e14d";
export var planContent = "home-internet-module--planContent--7598e";
export var planContentHeader = "home-internet-module--planContentHeader--8a0ae";
export var planCurrency = "home-internet-module--planCurrency--d7540";
export var planItemList = "home-internet-module--planItemList--d42f7";
export var planItemListMainContainer = "home-internet-module--planItemListMainContainer--03f4d";
export var planName = "home-internet-module--planName--c4164";
export var planOffer = "home-internet-module--planOffer--b71d9";
export var planPrice = "home-internet-module--planPrice--86dfb";
export var planPriceContainer = "home-internet-module--planPriceContainer--cded2";
export var planPriceOriginal = "home-internet-module--planPriceOriginal--48245";
export var planTitle = "home-internet-module--planTitle--1de79";
export var plansContainer = "home-internet-module--plansContainer--feb6e";
export var plansSection = "home-internet-module--plansSection--0da3c";
export var reason = "home-internet-module--reason--70e5a";
export var reasonListContainer = "home-internet-module--reasonListContainer--15b52";
export var reasonSection = "home-internet-module--reasonSection--8e788";
export var sectionAfterHero = "home-internet-module--sectionAfterHero--5511e";
export var sectionAfterHowTo = "home-internet-module--sectionAfterHowTo--d54ce";
export var sectionAfterPlan = "home-internet-module--sectionAfterPlan--d3fd6";
export var sliderArrows = "home-internet-module--sliderArrows--5f7e1";
export var unlimitedDataItem = "home-internet-module--unlimitedDataItem--373fe";
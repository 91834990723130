import React, { useEffect, useRef, useState } from "react";
// import { useTranslation } from "react-i18next";
import * as Styles from "../css/home-internet.module.scss";
import PageLayout from "../../components/PageLayout";
import { graphql, navigate } from "gatsby";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoltLightning,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faInfinity,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "gatsby-plugin-react-i18next";
import { Carousel } from "react-bootstrap";
import GoogleAutoCompleteAddressHomeInternet, {
  AutoCompleteAddress,
} from "../../components/GoogleAutoCompleteAddressHomeInternet";
import { homeInternetApi } from "../../utilities/home-internet-api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  updateAddress,
  updatePromoCode,
  updateSelectedPlan,
  updateSelectedPlanId,
} from "../../redux/home-internet/homeInternetActions";
import { saveRouteDetails } from "../../redux/actions/routeActions";
import { goTo } from "../../utilities/utils";
import { HomeInternetPlan } from "../../home-internet-content/constant-and-types";

const faqs: { question: string; answer: string | string[] }[] = [
  {
    question: "What types of home internet plans are available?",
    answer:
      "If you are interested in home internet service with us, please visit our website and enter your address to view available plans and choose the one that best suits your needs. Depending on your location, we will offer you multiple options.",
  },
  {
    question: "Is there a data cap on the internet plan, or is it unlimited?",
    answer: [
      "We are offering an Unlimited data plan! Stream, game, work, and browse as much as you want without worrying about data limits.",
      "Note: Unlimited data for 24 months with TELUS plan, Unlimited data with no contract on Koodo.",
    ],
  },
  {
    question: "Which network provider do you partner with?",
    answer: "Telus is offered in Western Canada, and Koodo in Eastern Canada.",
  },
  {
    question: "Is there an equipment rental fee?",
    answer:
      "All our Home Internet plans include modem and Wi-Fi 6 router rental, with no shipping or equipment rental fees.",
  },
  {
    question:
      "How can I make sure the pricing and plan I selected is right for me?",
    answer: [
      "The prices listed for our home internet services are subject to availability and eligibility requirements. A check of your physical address is required to confirm that you qualify for the pricing shown. Eligibility will depend on your location and service availability in your area.",
      "Please note that these prices may vary based on factors such as service location, plan selection, and applicable technology in your area. We recommend verifying eligibility with us before proceeding with your order.",
      "For more details, please contact our customer service team, who will assist you with the address verification process.",
      "Additionally, consider your internet usage habits—such as speed requirements, the number of connected devices, your long term living situation in Canada, our customer service team is available to help you review your options and recommend the plan that best fits your needs.",
    ],
  },
];

const carouselContent: {
  title: string;
  subtitle: string;
  image: string;
  color: string;
}[] = [
  {
    title: "Exclusive Home Internet Now Available Across Canada",
    subtitle: "Unlock Amazing Discounts on High-Speed, Reliable Service",
    color: "#0094CA",
    image: "/images/home-internet/slide-1-image.png",
  },
  {
    title:
      "Sign Up for TELUS Home Internet and Get Lightning-Fast Speeds at the Best Price!",
    subtitle:
      "An Exclusive Treat for Mobile Users - Save More, and Experience Everything with PhoneBox",
    color: "#A2CB7E",
    image: "/images/home-internet/slide-2-image.png",
  },
  {
    title: "Refer a Friend and Earn Up to $90 + Extra Data!",
    subtitle:
      "Don't Keep PhoneBox to Yourself! The More You Refer, The Bigger the Rewards! Simple, Easy, and Rewarding!",
    color: "#F2AB40",
    image: "/images/home-internet/slide-3-image.png",
  },
];

type PlanItemProps = {
  plan: HomeInternetPlan;
  onSelectPlan: (plan: HomeInternetPlan) => void;
};

const PlanItem = ({ plan, onSelectPlan }: PlanItemProps) => {
  return (
    <div
      className={`${Styles.planContent} ${
        plan.planTitle1 ? Styles.isSpecialPlan : ""
      }`}
    >
      {plan.planTitle1 ? (
        <p className={Styles.planBanner}>
          <FontAwesomeIcon icon={faBoltLightning} />
          <span>{plan.planTitle1}</span>
        </p>
      ) : null}
      <div className={"planDetails"}>
        <p className={Styles.planTitle}>{plan.planTitle2}</p>
        <div className={Styles.planContentHeader}>
          <div className={Styles.planPriceContainer}>
            {plan.orgPrice !== plan.planPrice ? (
              <p className={Styles.planPriceOriginal}>${plan.orgPrice}/mo.</p>
            ) : null}
            <p className={Styles.planPrice}>
              <sup>$</sup>
              <span>{plan.planPrice}</span>
              <sub>/mo.</sub>
              <sup className={Styles.planCurrency}>CAD</sup>
            </p>
          </div>
          <p className={Styles.planName}>{plan.planFormal}</p>
        </div>
        <div className={Styles.planItemListMainContainer}>
          {plan.orgPrice !== plan.planPrice ? (
            <p className={Styles.planOffer}>
              Saving of ${plan.orgPrice - plan.planPrice}/mo.
            </p>
          ) : null}
          <hr />
          <div className={Styles.planItemList}>
            <div>
              <FontAwesomeIcon icon={faCheck} />
              <span>
                Download Speed: Up to{" "}
                {plan.speedDnload === 1024
                  ? "1"
                  : plan.speedDnload > 1024
                  ? (plan.speedDnload / 1024).toFixed(2)
                  : plan.speedDnload}
                {plan.speedDnload >= 1024 ? " Gbps" : " Mbps"}
              </span>
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} />
              <span>Upload Speed: Up to {plan.speedUpload} Mbps</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} />
              <span>Modem/WiFi Rental Included</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} />
              <span>No Activation Fee</span>
            </div>

            <div className={Styles.unlimitedDataItem}>
              {plan.dataCapGB === -1 ? (
                <FontAwesomeIcon icon={faInfinity} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faCheck} />
              )}
              <span>
                {plan.dataCapGB === -1 ? "Unlimited" : plan.dataCapGB} Data
                Usage
                {plan.dataCapGB === -1 ? (
                  <sup>
                    <a href="#info-2">(2)</a>
                  </sup>
                ) : null}
              </span>
            </div>
          </div>
        </div>
      </div>
      <button
        className={Styles.choosePlanButton}
        onClick={() => onSelectPlan(plan)}
      >
        Choose This Plan
      </button>
    </div>
  );
};

export default function homeInternet() {
  const dispatch = useDispatch();

  const [openFaq, setOpenedFaq] = useState<number>(0);
  const [isTelusOrKoodo, setIsTelusOrKoodo] = useState<boolean | null>(null);
  const [isCheckingForAvailability, setIsCheckingForAvailability] =
    useState<boolean>(false);
  const [address, setAddress] = useState<AutoCompleteAddress>(null);
  const [isServiceAvailableAtLocation, setIsServiceAvailableAtLocation] =
    useState<boolean>(null);
  const [promoCode, setPromoCode] = useState<string>("");
  const [plans, setPlans] = useState<HomeInternetPlan[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<number>(0);

  const plansContainerRef = useRef<HTMLDivElement>(null);
  const plansContainerArrowRef = useRef<HTMLDivElement>(null);

  const checkAvailability = async () => {
    if (isTelusOrKoodo !== false) {
      toast.error(
        "Please select whether your current internet provider is TELUS or Koodo?"
      );
      return;
    }

    if (address === null) {
      toast.error(
        "Please search for an address using the search bar to check the availability."
      );
      return;
    }

    try {
      setIsLoading(true);
      const response = await homeInternetApi.checkIfServiceAvailable(
        address.stateProvinceShortName
      );
      const responseData = response.data;
      if (responseData.success) {
        setIsServiceAvailableAtLocation(responseData.data);
        if (responseData.data) {
          await getPlans(address.stateProvinceShortName);
        }
        if (
          selectedPlan &&
          !checkIfSelectedPlanAvailableForSelectedAddress(selectedPlan)
        ) {
          setSelectedPlan(0);
          dispatch(updateSelectedPlan(null) as any);
          dispatch(updateSelectedPlanId(0) as any);
          toast.error(
            "We're Sorry. The Plan you selected is not available at your location, Please select another plan."
          );
          setIsCheckingForAvailability(false);
          return;
        }
        if (selectedPlan) {
          setTimeout(() => {
            navigate("checkout");
          }, 0);
        }
        setIsCheckingForAvailability(false);
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error("Unexpected Error occurred, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const getPromoCode = async () => {
    try {
      const response = await homeInternetApi.getPromocode();
      if (response.data.success) {
        return response.data.data;
      }
      alert(response.data.message);
      return "";
    } catch (error) {
      alert("Unknown Error");
    }
  };

  const getPlans = async (province: string) => {
    setIsLoading(true);
    let _promoCode: string = promoCode;
    if (!promoCode) {
      _promoCode = await getPromoCode();
      setPromoCode(_promoCode);
      dispatch(updatePromoCode(_promoCode) as any);
    }

    try {
      const plans = await homeInternetApi.getPlanList(province, _promoCode);
      const planData = plans.data;
      if (planData.success) {
        let plans = planData.data.planDetails;
        if (province === "") {
          plans = plans.filter(
            (plan) =>
              (/ON/i.test(plan.serviceArea) && /rogers/i.test(plan.planNote)) ||
              /telus/i.test(plan.planNote)
          );
        }
        setPlans(plans);
      } else {
        toast.error("Failed to fetch plans. Please try again later.");
      }
    } catch (error) {
      alert("Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  const checkIfSelectedPlanAvailableForSelectedAddress = (planId: number) => {
    const availablePlan = plans.find((plan) => plan.planId === planId);
    if (availablePlan === undefined) {
      return false;
    }
    if (
      availablePlan.serviceArea
        .toLowerCase()
        .includes(address.stateProvinceShortName.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const onSelectPlan = (plan: HomeInternetPlan) => {
    setSelectedPlan(plan.planId);
    dispatch(updateSelectedPlanId(plan.planId) as any);
    dispatch(updateSelectedPlan(plan) as any);
    if (isServiceAvailableAtLocation === null) {
      toast.warn(
        "Please check if the service is available at your location before proceeding further."
      );
      setIsCheckingForAvailability(true);
      return;
    }

    if (isServiceAvailableAtLocation === false) {
      toast.error(
        "We're Sorry. PhoneBox is not able to support at your current location."
      );
      return false;
    }
    navigate("checkout");
  };

  const handleWindowResize = () => {
    if (
      plansContainerRef.current.scrollWidth ===
      plansContainerRef.current.clientWidth
    ) {
      plansContainerRef.current.style.justifyContent = "center";
      plansContainerArrowRef.current.style.display = "none";
    } else {
      plansContainerRef.current.style.justifyContent = "unset";
      plansContainerArrowRef.current.style.display = "flex";
    }
  };

  useEffect(() => {
    // Reset store. Left state management as-is due to legacy code; chose not to modify it.
    dispatch(saveRouteDetails("home-internet") as any);
    dispatch(updateAddress(null) as any);
    dispatch(updatePromoCode("") as any);
    dispatch(updateSelectedPlanId(0) as any);
    dispatch(updateSelectedPlan(null) as any);

    getPlans("");
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    handleWindowResize();
  }, [plans]);

  return (
    <PageLayout
      showPartnerNav
      containerClassName={Styles.homeInternetContainer}
    >
      <Helmet>
        <style>
          {`
            body:has(.crisp-client #crisp-chatbox) .chat-message-text-fixed-info.chat-message-text-fixed-info {display: block !important}
            body .slick-prev:before, body .slick-next:before {color: #000000}
          `}
        </style>
      </Helmet>

      <title>
        <Trans>Home Internet | PhoneBox</Trans>
      </title>

      <section id={Styles.heroSection}>
        <Carousel interval={10000 /** 10 Seconds */} touch={true}>
          {carouselContent.map((item, i) => (
            <Carousel.Item key={i} style={{ backgroundColor: item.color }}>
              <div
                style={{ backgroundColor: item.color }}
                className={Styles.carouselItem}
              >
                <div>
                  <h3>{item.title}</h3>
                  <p>{item.subtitle}</p>
                  <button>
                    <a href="#check-availability-form">Get Started</a>
                  </button>
                </div>
                <img src={item.image} alt={item.title} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        <div
          className={Styles.checkAvailabilityForm}
          onClick={() => {
            setIsCheckingForAvailability(true);
          }}
          id="check-availability-form"
        >
          <input
            type="text"
            placeholder="Enter your address to see available plans!"
            value={address !== null ? address.fullAddress : ""}
            disabled={true}
          />
          <button className={Styles.blackButton}>Address Confirmation</button>
        </div>
      </section>

      <section id={Styles.sectionAfterHero}>
        <div>
          <img
            src="/images/home-internet/pen-note.png"
            alt="Pen writing on paper"
          />

          <div>
            <h6>First Time Here? Don't Miss Out</h6>
            <p>
              Claim Your $100 Sign-Up Bonus Today and Experience the Best in
              Home Internet Service!
              <sup>
                <a href="#info-1">(1)</a>
              </sup>
            </p>
            {isServiceAvailableAtLocation === null && (
              <button
                className={Styles.blackButton}
                onClick={() => setIsCheckingForAvailability(true)}
              >
                Join Now
              </button>
            )}
          </div>
        </div>
      </section>

      <section id={Styles.plansSection}>
        <div>
          <h2>Find Your Perfect Home Internet Plan!</h2>
          <p>
            Pricing and plan availability are subject to verification based on
            your physical address. A final confirmation will be provided after a
            thorough review, and we will contact you directly via phone to
            confirm the details of your selected plan. For more information,
            please refer to the Q&A section for additional details on
            eligibility and pricing conditions.
          </p>
          <div
            id={Styles.plansContainer}
            ref={(p) => (plansContainerRef.current = p)}
          >
            {plans.map((plan) => (
              <PlanItem
                plan={plan}
                key={plan.planId}
                onSelectPlan={onSelectPlan}
              />
            ))}
          </div>
          <div
            id={Styles.sliderArrows}
            ref={(p) => (plansContainerArrowRef.current = p)}
          >
            <span
              onClick={() => {
                plansContainerRef.current.scrollLeft -= 312;
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
            <span
              onClick={() => {
                plansContainerRef.current.scrollLeft += 312;
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </div>
        </div>
      </section>

      <section id={Styles.sectionAfterPlan}>
        <div>
          <div>
            <h6>PhoneBox Users, Get Rewarded!</h6>
            <p>
              Save Up to $10 on Your Mobile Plan - Time to Switch and Save with
              Us!
            </p>
            <button className={Styles.blackButton}>
              <a
                href="/plans"
                onClick={(e) => {
                  e.preventDefault();
                  goTo("/plans");
                }}
              >
                Explore Our Mobile Plans
              </a>
            </button>
          </div>
          <img
            src="/images/home-internet/phone-monitor.png"
            alt="Image with monitor and phone"
          />
        </div>
      </section>

      <section id={Styles.howToSection}>
        <div>
          <h2>See How Simple It Is!</h2>
          <div className={Styles.howToStepsContainer}>
            <div className={Styles.howToStep}>
              <img
                src="/images/home-internet/order-step.png"
                alt="Image represent communication"
              />
              <span>1</span>
              <h4>Order</h4>
              <p>
                24/7 Support
                <br />
                in different languages
              </p>
            </div>
            <div className={Styles.howToStep}>
              <img
                src="/images/home-internet/set-up-step.png"
                alt="Image showing Modem and router"
              />
              <span>2</span>
              <h4>
                Set up Your
                <br />
                WiFi/Modem at Home
              </h4>
              <p>
                Yes,
                <br />
                All free!
              </p>
            </div>
            <div className={Styles.howToStep}>
              <img
                src="/images/home-internet/enjoy-step.png"
                alt="Image represent rocket launching showing that your internet is ready"
              />
              <span>3</span>
              <h4>
                Enjoy
                <br />
                High-Speed Internet
              </h4>
              <p>
                Easy peasy,
                <br />
                right?
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id={Styles.sectionAfterHowTo}>
        <div>
          <img
            src="/images/home-internet/high-five.png"
            alt="Image shows two hands high five each other"
          />
          <div>
            <h6>Share the Benefit, Save More!</h6>
            <p>
              Invite your friends to PhoneBox and earn up to $30
              <sup>
                <a href="#info-3">(3)</a>
              </sup>
            </p>
          </div>
        </div>
      </section>

      <section id={Styles.reasonSection}>
        <div>
          <h2>Top Reasons to Choose Our Internet</h2>
          <div className={Styles.reasonListContainer}>
            <div className={Styles.reason}>
              <img
                src="/images/home-internet/reason-money.png"
                alt="Image of cartoon person sitting on bunch of coins"
              />
              <div>
                <h4>Save More with Us Your Wallet Will Thank You</h4>
                <p>
                  Get more value with our exclusive mobility bundle!
                  <br />
                  Combine your mobile and internet services to enjoy a special
                  discount, making it easier and more affordable to stay
                  connected.
                </p>

                <button>
                  <a href="#check-availability-form">Sounds amazing!</a>
                </button>
              </div>
            </div>
            <div className={Styles.reason}>
              <img
                src="/images/home-internet/reason-nationwide.png"
                alt="Image of Canada with plans on top showing flight"
                style={{
                  height: "400px",
                }}
              />
              <div>
                <h4>Nationwide in Canada</h4>
                <p>
                  PhoneBox offers exclusive high-speed home internet service,
                  available nationwide across Canada. Get the best value for
                  your internet needs, wherever you are in Canada.
                </p>

                <button>
                  <a href="#check-availability-form">Awesome!</a>
                </button>
              </div>
            </div>
            <div className={Styles.reason}>
              <img
                src="/images/home-internet/reason-installation.png"
                alt="Image of cartoon person sitting on bunch of coins"
              />
              <div>
                <h4>Installation, Faster than Fast food.</h4>
                <p>
                  Enjoy hassle-free installation with our high-speed home
                  internet service. Our team ensures a smooth setup process, so
                  you can get connected quickly and easily.
                </p>

                <button>
                  <a href="#check-availability-form">See It Now!</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id={Styles.faqSection}>
        <div>
          <h2>
            Got a Question About Home Internet?
            <br />
            Find the Answers You Need Right Here!
          </h2>
          <div className={Styles.faqListContainer}>
            {faqs.map((faq, i) => (
              <div
                className={`${Styles.faqItem} ${
                  openFaq === i ? Styles.faqOpen : ""
                }`}
                key={faq.question}
                onClick={() => {
                  setOpenedFaq(i);
                }}
              >
                <div className={Styles.faqQuestion}>
                  <p>{faq.question}</p>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                {Array.isArray(faq.answer) ? (
                  <p className={Styles.faqAnswer}>
                    {faq.answer.map((answer, i) => (
                      <p key={i}>{answer}</p>
                    ))}
                  </p>
                ) : (
                  <p className={Styles.faqAnswer}>{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id={Styles.infoSection}>
        <div>
          <p id="info-1">
            1) New sign up bonus for $100
            <br />
            Only applicable for new users, not applicable for existing customers
            using TELUS or Koodo Internet. Promo is valid thru March 31, 2025
          </p>
          <p id="info-2">
            2) Unlimited Data
            <br />
            Available to PhoneBox customers who purchase qualifying TELUS Home
            Services product(s). A $15/month discount will be applied to the
            Home Services bill for 24 months. Cancellation charge may apply if
            cancelled within 24 months. Cancellation charge is $15 x remaining
            months of 24 months.
          </p>
          <p id="info-3">
            3) Friend&apos;s referral bonus
            <br />
            The referring customer must be an active PhoneBox mobile account for
            at least 30 days and maintain the mobile account throughout the
            period of promotion. To be eligible for the PhoneBox Friends
            Referral Reward, the Referee must use the activation link provided
            by the Referrer to become a new PhoneBox customer. The Referrer and
            Referee must have different service start dates, with the
            Referee&apos;s activation date being exactly one month after the
            Referrer&apos;s. Referrals are not eligible if the new customer
            registers through a partner, including plans offered, purchased, or
            provided by one of our partners. Referral credits are based on the
            new mobile plan: $20 for Monthly Plans under $40, $30 for Monthly
            Plans over $40, and $5 for Prepaid Plans (excluding 7-day short-term
            plans). There is a limit of 15 eligible referrals per calendar year,
            with the referral count resetting on January 1st. Referrals will be
            declined if either account is terminated or suspended during the
            credit application process, and referral credits are non-refundable
            and non-transferable. Referrals from the same account holder are not
            eligible, and PhoneBox reserves the right to revoke referral credits
            if the program is misused or manipulated in violation of our
            acceptable use policy.
          </p>
        </div>
        {/* info-3 */}
      </section>

      <p id={Styles.chatMessageFixed} className="chat-message-text-fixed-info">
        Have more questions? We're happy to assist!
      </p>

      {isCheckingForAvailability ? (
        <div
          id={Styles.checkAvailabilityPopUpForm}
          onClick={() => {
            setIsCheckingForAvailability(false);
          }}
        >
          <div
            className={Styles.checkAvailabilityForm}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={Styles.formPart1}>
              <div className={Styles.formPart1LeftSide}>
                {isServiceAvailableAtLocation !== false ? (
                  <>
                    <h3>Is your current internet provider TELUS or Koodo?</h3>
                    <div>
                      <span>
                        <input
                          type="radio"
                          name="is-telus-or-koodo"
                          id="isTelusOrKoodo-No"
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setIsTelusOrKoodo(false);
                            }
                          }}
                        />
                        <span>No</span>
                      </span>
                      <span>
                        <input
                          type="radio"
                          name="is-telus-or-koodo"
                          id="isTelusOrKoodo-Yes"
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setIsTelusOrKoodo(true);
                            }
                          }}
                        />
                        <span>Yes</span>
                      </span>
                    </div>
                    {isTelusOrKoodo ? (
                      <p>
                        We&apos;re Sorry. PhoneBox is not able to support your
                        current internet provider if it is TELUS or Koodo.{" "}
                        <br /> Please contact TELUS/Koodo customer support for
                        further assistance (+1-855-886-0505).
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </>
                ) : (
                  <>
                    <h3>
                      We&apos;re Sorry.
                      <br />
                      PhoneBox is not able to support at your current location.
                    </h3>
                    <p>
                      Our chatbot on the website can assist you anytime. or give
                      us call on{" "}
                      <a href="tel:+1-855-886-0505">+1-855-886-0505</a>,
                      we&apos;re here to help.
                    </p>
                  </>
                )}
              </div>
              <img
                src={
                  isServiceAvailableAtLocation !== false
                    ? "/images/home-internet/check-availability.png"
                    : "/images/home-internet/sad-image.png"
                }
                alt="Announcement speaker"
              />
            </div>
            <div className={Styles.formPart2}>
              {isTelusOrKoodo ? (
                <input
                  type="text"
                  placeholder="Enter your address to see available plans!"
                  value={address !== null ? address.fullAddress : ""}
                  disabled={true}
                />
              ) : (
                <GoogleAutoCompleteAddressHomeInternet
                  onAutoCompleteAddress={(address) => {
                    if (address.zipPostal) {
                      setAddress(address);
                      dispatch(updateAddress(address) as any);
                    } else {
                      toast.error(
                        "We're Sorry. PhoneBox is not able to verify your address. Please try your full address"
                      );
                    }
                  }}
                />
              )}
              <button
                className={Styles.blackButton}
                onClick={checkAvailability}
                disabled={isLoading || isTelusOrKoodo}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
                ) : null}{" "}
                Address Confirmation
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
